const actions = {
	GET_PRODUCT_LIST : 'GET_PRODUCT_LIST',
	SET_PRODUCT_LIST : 'SET_PRODUCT_LIST',
	UPDATE_PRODUCT_LIST : 'UPDATE_PRODUCT_LIST',
	UPDATE_PRODUCT_LIST_SUCCESS: 'UPDATE_PRODUCT_LIST_SUCCESS',
	GET_PRICELIST_USER : 'GET_PRICELIST_USER',
	SET_PRICELIST_USER : 'SET_PRICELIST_USER',
	getProductList : (payload) => ({
		type: actions.GET_PRODUCT_LIST,
		payload: payload	
	}),
	updateProductList : (payload) => ({
		type: actions.UPDATE_PRODUCT_LIST,
		payload: payload	
	}),
	getPriceListUser : (payload) => ({
		type: actions.GET_PRICELIST_USER,
		payload: payload	
	})
};

export default actions;
