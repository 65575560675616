import actions from './actions';

const initState = { 
	priceRequests : []
};


export default function priceRequestsReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_PRICE_REQUESTS:
			return {
				...state,
				priceRequests : action.payload
			}
		default:
			return state;
	}
}
