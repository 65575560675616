import actions from './actions';

const initState = { 
	user: null, 
	user_profile: {
		user_credit: '0,00'
	}, 
	sub_user: null, 
	paymentHistory: null 
};

export default function userReducer(state = initState, action) {
	
	switch (action.type) {
		case actions.SET_USER_PROFILE:
			return { 
				...state, 
				user_profile: {
					...state.user_profile,
					...action.payload
				}
			};	
		case actions.SET_USER_PROPERTIES:

			return {
				...state,
				user_profile: {
					...state.user_profile,
					...action.payload
				}
			}	
		case actions.SET_SUB_USERS:	
	
		return {
				...state,
				sub_users: {
					...state.sub_users,
					...action.payload
				}
			}	
		case actions.LOGOUT:
			return initState;


		default:
			return state;
	}
}
