import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';

import FirebaseHelper from '../../helpers/firebase';


import { toast } from 'react-toastify';
import _ from 'lodash/collection';

const { rsf, rsfFirestore } = FirebaseHelper;


export function* addProductGroup() {
	yield takeEvery(actions.ADD_PRODUCT_GROUP, function*(action) {
		
		let productGroup = {...action.payload};
		
		try {
			yield call(rsfFirestore.addDocument, 'product_groups', productGroup);
			toast("Productgroep is succesvol toegevoegd");

	    } catch (e) {
	      	console.log(e);
	        return;
	    }
	});
}

export function* syncProductGroupCollection() {
	yield fork(
		rsfFirestore.syncCollection, 
		'product_groups', 
		{ successActionCreator: (snap) => actions.processProductGroups(FirebaseHelper.processFireStoreCollection(snap))}
	); 	
}

export default function* rootSaga() {
	yield all([
		fork(syncProductGroupCollection),
		fork(addProductGroup)
	]);
}
