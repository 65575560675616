import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { fetchData } from '../../helpers/fetch';
import { toast } from 'react-toastify';
import { getToken, getClientPath } from '../../helpers/utility';
import { errorCodes } from '../../helpers/errorMessage';

const { rsfFirestore,firebase } = FirebaseHelper;

function fetchUser() {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.reload().then((result) => {
		  	resolve(user);
		})
	});
}

function updatePassword(newPassword) {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updatePassword(newPassword).then((result) => {
			
			toast('Wachtwoord geupdated', {
		        type: 'success',
		        position: 'top-right'
		    })
		    resolve(result);
		}).catch((err) => {
			console.log(err)
			toast(errorCodes[err.code]['message'], {
		        type: errorCodes[err.code]['type'],
		        position: 'top-right'
		    })
		});
	});
}

function updateEmail(emailAddress) {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updateEmail(emailAddress).then((result) => {
			toast('E-mailadres geupdated', {
		        type: 'success',
		        position: 'top-right'
		    })
		    resolve(result);
		}).catch((err) => {
			toast(errorCodes[err.code]['message'], {
		        type: errorCodes[err.code]['type'],
		        position: 'top-right'
		    })
		});
	});
}

function updateDisplayName(displayName) {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updateProfile({displayName: displayName}).then((result) => {
			
			toast('Gebruikersnaam geupdated', {
		        type: 'success',
		        position: 'top-right'
		    })
		    resolve(result);
		}).catch((err) => {
			toast(err.code, {
		        type: 'error',
		        position: 'top-right'
		    })
		});
	});
}



export function* setUserProfile() {
	yield takeEvery(actions.SET_USER_PROFILE, function*(action) {
		yield localStorage.setItem('user_profile', JSON.stringify(action.payload));

	})
}

export function* fetchUserProperties() {
	yield takeEvery(actions.FETCH_USER_PROPERTIES, function*(action) {


		const snapshot = yield call(rsfFirestore.getDocument, 'users/'+action.payload);
		yield put({
			type: actions.SET_USER_PROPERTIES,
			payload: snapshot.data()
		})		
	})
}


export function* userUpdate() {
	yield takeEvery(actions.USER_UPDATE, function*(action) {

		// -- Update firestore userdata
		const user = FirebaseHelper.auth().currentUser;
		if(user.displayName !== action.payload.displayName) {
			yield updateDisplayName(action.payload.displayName);
		}

		if(action.payload.password) {
			yield updatePassword(action.payload.password);
		}
		if(user.email !== action.payload.email) {
			yield updateEmail(action.payload.email);
		}


		// -- Create user formData
		let formData = new FormData();
		formData.append('user_id', user.uid);
		formData.append('token', user.uid);
		formData.append('firstname', action.payload.firstname);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone_number);
		formData.append('company', action.payload.company);
		formData.append('zipcode', action.payload.zipcode);

		// -- Send formdata to API
		const response = yield call(
			fetchData, 
			getClientPath() + '/user/updateDbProfile',
			formData
		)

		// -- Update firestore document with server response
		try {
			yield call(rsfFirestore.updateDocument, 'users/'+user.uid, response);	
		} catch(error) {
			console.log(error)
		}
		
		
		// -- Fetch user itself from firestore
		const userResult = yield fetchUser();
		
		if(action.payload.logoBlob) {
			try {

			// Create a root reference
			var storageRef = firebase.storage().ref();

			// Create a reference to 'images/mountains.jpg'
			var logoImagesRef = storageRef.child(user.uid+'/'+action.payload.logoName);

			var metadata = {
			  contentType: action.payload.logoType
			};
			
			logoImagesRef.put(action.payload.logoBlob, metadata).then(function(snapshot){
				// -- set logo url
				snapshot.ref.getDownloadURL().then(function(downloadURL) {
				   firebase.firestore().collection('users').doc(user.uid).set({logo: downloadURL, logo_name: action.payload.logoName}, {merge: true});
				}); 
					
			});

			} catch(error) {
				console.log(error)
			}
		}
		
		yield put({
			type:actions.SET_USER_PROFILE, 
			payload: userResult
		})
	});
}

export function* createNewUser() {
	yield takeEvery(actions.USER_ADD, function*(action) {		
		// -- Create user formData
		let formData = new FormData();		
		formData.append('token', FirebaseHelper.auth().currentUser.uid);
		formData.append('firstname', action.payload.firstname);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone_number);
		formData.append('company', action.payload.company);
		formData.append('zipcode', action.payload.zipcode);
		formData.append('email', action.payload.email);
		formData.append('password', action.payload.password);
		formData.append('userType', action.payload.userType.value);
		formData.append('quota', action.payload.quota);
		formData.append('price_per_volume', action.payload.price_per_volume);
		formData.append('quotum_infinite', action.payload.quotum_infinite);
		formData.append('user_pays_later', action.payload.user_pays_later);
		
		const response = yield call(
			fetchData, 
			getClientPath() + '/user/createNewUser',
			formData
		)

		if(response.userDocument) {

			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })
			
			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, user_type: firebase.firestore().doc('user_types/' + response.userDocument.user_type_id)}

			response.userDocument = {...response.userDocument, user_type_permissions: firebase.firestore().doc('user_type_permissions/' + response.userDocument.user_type_id)}
			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, parent_user: firebase.firestore().doc('users/' + response.userDocument.parent_id)}

			yield call(rsfFirestore.setDocument, 'users/'+response.userDocument.firebase_user_id, response.userDocument)


		}
	});
}

export function* updateSubUser() {
	yield takeEvery(actions.SUB_USER_UPDATE, function*(action) {		
		// -- Create user formData
		let formData = new FormData();		
		formData.append('token', FirebaseHelper.auth().currentUser.uid);
		formData.append('firebase_user_id', action.payload.firebase_user_id);
		formData.append('firstname', action.payload.firstname);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone_number);
		formData.append('company', action.payload.company);
		formData.append('zipcode', action.payload.zipcode);
		formData.append('userType', action.payload.userType.value);
		formData.append('quota', action.payload.quota);
		formData.append('price_per_volume', action.payload.price_per_volume);
		formData.append('quotum_infinite', action.payload.quotum_infinite);
		formData.append('user_pays_later', action.payload.user_pays_later);

		const response = yield call(
			fetchData, 
			getClientPath() + '/user/updateSubUser',
			formData
		)

		if(response.userDocument) {

			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })

		    // -- replace user type data with reference
			response.userDocument = {...response.userDocument, user_type: firebase.firestore().doc('user_types/' + response.userDocument.user_type_id)}

		    yield call(rsfFirestore.updateDocument, 'users/'+action.payload.firebase_user_id, response.userDocument);	
			
			/*
			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, user_type: firebase.firestore().doc('user_types/' + response.userDocument.user_type_id)}
			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, parent_user: firebase.firestore().doc('users/' + response.userDocument.parent_id)}

			yield call(rsfFirestore.setDocument, 'users/'+response.userDocument.firebase_user_id, response.userDocument)
			*/

		}
	});
}


export function* removeLogo() {
	yield takeEvery(actions.REMOVE_LOGO, function*(action) {		
		const localUserData = getToken();
		const user_id = localUserData.get('userId');
		const snapshot = yield call(rsfFirestore.getDocument, 'users/'+user_id);
		const userData = snapshot.data();
		if(userData) {
			
			try {

				var storageRef = firebase.storage().ref();
				// Create a reference to 'images/mountains.jpg'
				var logoImagesRef = storageRef.child(user_id+'/'+userData.logo_name);

				// Delete the file
				logoImagesRef.delete().then(function() {

					firebase.firestore().collection('users').doc(user_id).set({logo: '', logo_name: ''}, {merge: true});

					toast('Logo succesvol verwijderd', {
				        type: 'success',
				        position: 'top-right'
				    })


				}).catch(function(error) {
				
				});
			} catch(error) {
				console.log(error)
			}
		}
		//

	});
}



export function* syncUserCollection() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');

	// -- Sync user document and set user properties with snapped data on change.
	yield fork(
		rsfFirestore.syncDocument, 
		'users/'+user_id, 
		{ successActionCreator: (snap) => actions.setUserProperties(snap.data())}
	); 	
}

export function* syncSubUsers() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');
	
	yield fork(
	  	rsfFirestore.syncCollection,
	  	firebase.firestore().collection('users').where('parent_id', '==', user_id),
		{ successActionCreator: (snap) => actions.setSubUsers(FirebaseHelper.processFireStoreCollection(snap))}
	  )	
}


export default function* rootSaga() {
	yield all([
		fork(userUpdate),
		fork(createNewUser),
		fork(setUserProfile),
		fork(fetchUserProperties),
		fork(syncUserCollection),
		fork(removeLogo),
		fork(syncSubUsers),
		fork(updateSubUser)
		
	]);
}
