import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';



const history = createHistory();

const routersMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware();
const middlewares = [ sagaMiddleware, routersMiddleware ];

const store = createStore(
	combineReducers({
		...reducers,
	}),
	compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export { store, history };
