import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import './styles/themes/theme-e.scss'

import Boot from "./redux/boot";
global.appURL = 'https://dps.acceptage.nl';
global.serverURL = 'https://api.dps.acceptage.nl/api/public/';
class App extends Component {

    componentWillMount() {
        Boot();
    }


  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
        <HashRouter basename={basename}>
            <Routes />
        </HashRouter>
    );

  }
}

export default App;
