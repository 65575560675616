import Auth from './auth/reducer';
import User from './user/reducer';
import Product from './product/reducer';
import settings from './settings/reducer';
import PriceRequests from './priceRequests/reducer';
import ProductList from './productList/reducer';

export default {
	Auth,
	settings,
	User,
	Product,
	PriceRequests,
	ProductList
};
