import React, { Suspense, lazy, Component } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';
import  actions  from './redux/auth/actions';
import RouteWrap from './components/Common/RouteWrap';
import { bindActionCreators } from 'redux';
/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const Dashboard = lazy(() => import('./components/Dashboard/DashboardV1'));
const Login = lazy(() => import('./components/Pages/Auth/Login'));
const Register = lazy(() => import('./components/Pages/Auth/Register'));
const Recover = lazy(() => import('./components/Pages/Auth/Recover'));
const NotFound = lazy(() => import('./components/Pages/App/NotFound'));

const ProductGroup = lazy(() => import('./components/Pages/Product/ProductGroup'));
const ProductLists = lazy(() => import('./components/Pages/ProductLists/ProductLists'));
const ProductListsDetail = lazy(() => import('./components/Pages/ProductLists/ProductListsDetail'));

// -- add components to list
const componentList = {   
    'Dashboard' : Dashboard,
    'ProductGroup' : ProductGroup,
    'ProductLists' : ProductLists,
    'ProductListsDetail' : ProductListsDetail
}



// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
    '/recover',
    '/notfound'    
];

class Routes extends Component {
    
    _mapRoutes = (routeObject) => {
        return (
            <Route 
                key={routeObject.pageKey} 
                exact path={routeObject.route} 
                render={ (props) =>
                    <RouteWrap component={componentList[routeObject.component]}/>
                }
                />

            );
    }
    
    
    render() {
        
        const location = this.props.location;
        const currentKey = location.pathname.split('/')[1] || '/';
        const timeout = { enter: 500, exit: 500 };

        // Animations supported
        //      'rag-fadeIn'
        //      'rag-fadeInRight'
        //      'rag-fadeInLeft'

        const animationName = 'rag-fadeIn';

        // -- Check if user is logged in.
        
        
        if(!this.props.isLoggedIn && location.pathname !== '/login') {
            return (
                <Switch>
                    <Redirect to="/login" />
                </Switch>
            )
        }
        else{
            if(this.props.isLoggedIn && location.pathname === '/login') {
              
                return (
                    <Switch>
                        <Redirect to="/productlijsten" />
                    </Switch>
                )
            }
        }
        

        if(listofPages.indexOf(location.pathname) > -1) {
            return (
                // Page Layout component wrapper
                <BasePage>       
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route 
                            path="/login"
                            render={ (props) =>
                                <RouteWrap component={Login}/>
                            }
                        />
                        <Route 
                            path="/register"
                            render={ (props) =>
                                <RouteWrap component={Register}/>
                            }
                        />
                        <Route 
                            path="/recover"
                            render={ (props) =>
                                <RouteWrap component={Recover}/>
                            }
                        />                  
                    </Switch>
                    </Suspense>
                </BasePage>
            )
        }
        else {
            return (
                // Layout component wrapper
                // Use <BaseHorizontal> to change layout

                <Base>
                
                  <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                        
                                        
                                        <Route 
                                            path="/logout"
                                            render={ (props) =>
                                                <RouteWrap component={Dashboard}/>
                                            }
                                        />
                                    
                                     
                                        {/*Get custom routes, but only for the base pages*/}
                                        {this.props.routes ? this.props.routes.filter(route => route.pageKey === 'Base').map((item) => this._mapRoutes(item)) : null}
                                                   
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                  </TransitionGroup>
                </Base>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token !== null ? true : false,
        routes: state.Auth.routes,       
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))