import actions from './actions';

const initState = { product_groups: null };

export default function productReducer(state = initState, action) {
	switch (action.type) {
		case actions.PROCESS_PRODUCT_GROUPS:
			return { 
				...state, 
				product_groups: action.payload		
			}; 		
		case actions.LOGOUT:
			return initState;
		default:
			return state;
	}
}
