const actions = {	
	FETCH_ALL_PRODUCT_GROUPS : 'FETCH_ALL_PRODUCT_GROUPS',
	PROCESS_PRODUCT_GROUPS : 'PROCESS_PRODUCT_GROUPS',
	ADD_PRODUCT_GROUP : 'ADD_PRODUCT_GROUP',
	
	processProductGroups : (payload) => ({
		type: actions.PROCESS_PRODUCT_GROUPS,
		payload: payload
	}),

	addProductGroups : (payload) => ({
		type: actions.ADD_PRODUCT_GROUP,
		payload: payload	
	})
};

export default actions;
