import actions from './actions';

const initState = { 
	productList : {},
	user : {}
};

export default function productListReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_PRODUCT_LIST:
			return {
				...state,
				productList : action.payload
			}
		case actions.SET_PRICELIST_USER:
			
			return {
				...state,
				user : action.payload
			}
		default:
			return state;
	}
}
