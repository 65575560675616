import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import { getToken } from '../../helpers/utility';
import FirebaseHelper from '../../helpers/firebase';
import actions from './actions';

const { rsfFirestore, firebase } = FirebaseHelper;

function userLoggedIn() {
    const localUserData = getToken();
    const user_id = localUserData.get('userId');
    return user_id;
}

export function* syncProductListCollection() {
    const localUserData = getToken();
    const user_id = localUserData.get('userId');
    
    if(user_id){
        yield fork(
            rsfFirestore.syncCollection,
            firebase.firestore().collection('price_requests'),
            { successActionCreator: (snap) => actions.setPriceRequests(FirebaseHelper.processFireStoreCollection(snap))}
        )
    }

}

export default function* rootSaga() {
    yield all([
        fork(syncProductListCollection)
    ]);
}
