import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import productSagas from './product/saga';
import priceRequestsSagas from './priceRequests/saga';
import productListSagas from './productList/saga';

export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		userSagas(),
		productSagas(),
		priceRequestsSagas(),
		productListSagas()
	]);
}
