const actions = {
	GET_PRICE_REQUESTS : 'GET_PRICE_REQUESTS',
	SET_PRICE_REQUESTS : 'SET_PRICE_REQUESTS',
	SYNC_PRODUCT_REQUESTS : 'SYNC_PRODUCT_REQUESTS',
	
	getPriceRequests : ( payload ) => ({
		type : actions.GET_PRICE_REQUESTS,
		payload : payload
	}),
	setPriceRequests : ( payload ) => ({
		type : actions.SET_PRICE_REQUESTS,
		payload : payload
	})	
};

export default actions;
