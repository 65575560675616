const actions = {
	TOGGLE_SETTING  : 'TOGGLE_SETTING',
	CHANGE_SETTING  :  'CHANGE_SETTING',
	changeSetting: (name, value) => ({		
	    type: actions.CHANGE_SETTING,
	 	name,
	 	value
	  }),
	toggleSetting: (name) => ({
		
		type: actions.TOGGLE_SETTING,
		name
	})
}

export default actions;