import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';

import {store} from './redux/store';



const url = window.location.host.split(':');

if(url[0] === 'localhost') {
    localStorage.setItem('clientPath','http://localhost:8000'); 
    //store.store.dispatch(setClientPath('http://local.mijndp.nl'));asdfasdfasdfasdfasdf
} else {
    localStorage.setItem('clientPath','https://' + window.location.host);
}


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);
