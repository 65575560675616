const actions = {
	USER_UPDATE: 'USER_UPDATE',
	USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
	SET_USER_PROFILE : 'SET_USER_PROFILE',
	FETCH_USER_PROPERTIES : 'FETCH_USER_PROPERTIES',
	SET_USER_PROPERTIES: 'SET_USER_PROPERTIES',
	USER_ADD: 'USER_ADD',
	SUB_USER_UPDATE : 'SUB_USER_UPDATE',
	USER_ADD_SUCCESS : 'USER_ADD_SUCCESS',
	SET_SUB_USERS : 'SET_SUB_USERS',
	REMOVE_LOGO : 'REMOVE_LOGO',

	updateUser: (payload) => ({
	    type: actions.USER_UPDATE,
	    payload: payload
	}),

	addUser: (payload) => ({
	    type: actions.USER_ADD,
	    payload: payload
	}),

	updateSubUser : (payload) => ({
		type: actions.SUB_USER_UPDATE,
		payload: payload
	}),

	setUserProperties: (payload) => ({
		type: actions.SET_USER_PROPERTIES,
		payload: payload
	}),

	setSubUsers: (payload) => ({
		type: actions.SET_SUB_USERS,
		payload: payload
	}),

	userUpdated: (payload) => ({
	    type: actions.USER_UPDATE_SUCCESS,
	    payload: payload
	}),

	userAdded: (payload) => ({
	    type: actions.USER_ADD_SUCCESS,
	    payload: payload
	}),

	setUserProfile: (payload) => ({
		type: actions.SET_USER_PROFILE,
		payload: payload,
	}),

	removeLogo: () => ({
		type: actions.REMOVE_LOGO		
	}),

	


	


};

export default actions;
