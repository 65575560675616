import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import { toast } from 'react-toastify';
import FirebaseHelper from '../../helpers/firebase';
const { rsf, rsfFirestore } = FirebaseHelper;

export function* getProductList() {
    yield takeEvery(actions.GET_PRODUCT_LIST, function*(action) {
        
        const {listId} = action.payload;
        
        try {
            if(listId){
                const snapshot = yield call(rsfFirestore.getDocument, 'product_lists/' + listId);
                if(snapshot.exists){
                    const productList = snapshot.data();
                    yield put({
                        type: actions.SET_PRODUCT_LIST,
                        payload: productList             
                    });                    
                }
            }
        } catch (e) {
            console.log(e);
            return;
        }
    });
}

export function* updateProductList() {
    yield takeEvery(actions.UPDATE_PRODUCT_LIST, function*(action) {
        const {configurations, delivery_date, listId, can_be_ordered, priceRequest, productList} = action.payload;
        try {
            yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'configurations', configurations);
            yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'delivery_date', delivery_date);
            yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'can_be_ordered', can_be_ordered);

            const data = {
                priceRequest,
                productList
            }

            yield call(fetch, global.serverURL +'price_request/listUpdated', {
                'method': 'POST',		  
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin,Headers',      	
                body: JSON.stringify(data)
            });	

            toast('Productlijst succesvol aangepast', {
                type: 'success',
                position: 'top-right'
            })
        }
        catch (e) {
            console.log(e);
            return;            
        }

        yield put({
            type: actions.UPDATE_PRODUCT_LIST_SUCCESS,
            payload: {}             
        });             

    });
}

export function* getPriceListUser() {
   
    yield takeEvery(actions.GET_PRICELIST_USER, function*(action) {
        const user_id = action.payload.user_id;
       
        try {
            if(user_id){
                const snapshot = yield call(rsfFirestore.getDocument, 'users/' + user_id);
                
                if(snapshot.exists){

                    const user = snapshot.data();

                   

                    yield put({
                        type: actions.SET_PRICELIST_USER,
                        payload: user             
                    });                    
                }
            }
        } catch (e) {
            console.log(e);
            return;
        }    

    });
}


export default function* rootSaga() {
    yield all([
        fork(getProductList),
        fork(getPriceListUser),
        fork(updateProductList)
    ]);
}
